<template>
  <section v-editable="blok" hero-lottie :style="[bgStyle]">
    <div class="container container--bleed">
      <div class="section-row">
        <div class="section-content">
          <h1 class="section-title" v-if="blok.title" v-html="blok.title"></h1>
          <div class="section-description" v-if="blok.description" v-html="blok.description"></div>
          <div v-if="blok.buttons" class="section-buttons">
            <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
          </div>
        </div>
        <div class="section-image" v-if="blok?.json?.length > 0">
          <client-only>
            <div class="lottie-wrapper">
              <Vue3Lottie ref="canvas" :animationLink="blok.json" :height="646" width="auto" background="transparent" @on-loop-complete="animationComplete"></Vue3Lottie>
            </div>
          </client-only>
        </div>
        <div class="section-image" v-else>
          <img v-if="blok.image?.filename" :src="optimizeImage(blok.image.filename)" alt="Hero Banner Image" />
        </div>
      </div>
      <div class="section-partners" v-if="blok.partners">
        <label>Trusted by over 35,000 businesses globally</label>
        <div class="section-partners-row">
          <img :src="src.filename" v-for="(src, s) in blok.partners" :key="s" :alt="`Partner ${src.filename.replace('/img/', '').replace('-logo.svg', '')}`" />
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/hero-lottie.scss" lang="scss"></style>
<script setup>
  const props = defineProps({ blok: Object });

  const bgStyle = computed(() => {
    if (!props.blok.background?.filename) return {};
    return {
      backgroundImage: `url(${props.blok.background?.filename})`,
    };
  });

  const canvas = ref(null);

  const animationComplete = () => {
    canvas.value.goToAndPlay(59);
  };

  onMounted(() => {
    const typeText = async (element, words, speed = 1000, nextWordSpeed = 3000) => {
      for (const word of words) {
        for (let i = 0; i <= word.length; i++) {
          element.textContent = word.substring(0, i);
          await new Promise((resolve) => setTimeout(resolve, speed + i * (speed * 0.2)));
        }
        await new Promise((resolve) => setTimeout(resolve, nextWordSpeed));
      }

      typeText(element, words, speed, nextWordSpeed);
    };

    const animateText = (el) => {
      typeText(el, el.getAttribute("data-swap").split(","), 40, 2000);
    };

    document.querySelectorAll("[data-swap]").forEach((el) => {
      setTimeout(function () {
        animateText(el);
      }, 1000);
    });
  });
</script>
